import { useSelector } from 'react-redux';
import AlertMsg from './AlertMsg';

const Alert = () => {
	const alerts = useSelector((state) => state.alert);

	return (
		<>
			{alerts !== null &&
				alerts.length > 0 &&
				alerts.map((alert) => <AlertMsg alert={alert} key={alert.id} />)}
		</>
	);
};

export default Alert;
