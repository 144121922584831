import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const brands = createSlice({
	name: 'brands',
	initialState: {
		loading: false,
		loaded: false,
		brands: [],
		listings: [],
	},
	reducers: {
		BRANDS_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},
		BRANDS_SUCCESS(state, action) {
			const { brands, listings } = action.payload;
			return (state = {
				loading: false,
				loaded: true,
				brands,

				listings,
			});
		},
		BRANDS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const BRANDS_LOADING = brands.actions.BRANDS_LOADING;
export const BRANDS_SUCCESS = brands.actions.BRANDS_SUCCESS;
export const BRANDS_ERROR = brands.actions.BRANDS_ERROR;

export const GET_BRANDS = (props) => async (dispatch, getState) => {
	const { brands } = await getState();

	if (brands.loading) {
		return;
	}

	dispatch(BRANDS_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/admin/brands`)
		.then(async (res) => {
			dispatch(BRANDS_SUCCESS(res.data));
		})
		.catch((err) => {
			console.log(err);
			dispatch(BRANDS_ERROR());
		});
};

export default brands;
