import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const blogs = createSlice({
	name: 'blogs',
	initialState: {
		loading: false,
		loaded: false,
		blogs: [],
	},
	reducers: {
		BLOGS_LOADING(state, action) {
			return (state = { ...state, loading: true });
		},
		BLOGS_SUCCESS(state, action) {
			const { blogs } = action.payload;
			return (state = {
				loading: false,
				loaded: true,
				blogs,
			});
		},
		BLOGS_ERROR(state, action) {
			return (state = { ...state, loading: false });
		},
	},
});

export const BLOGS_LOADING = blogs.actions.BLOGS_LOADING;
export const BLOGS_SUCCESS = blogs.actions.BLOGS_SUCCESS;
export const BLOGS_ERROR = blogs.actions.BLOGS_ERROR;

export const GET_BLOGS = (props) => async (dispatch, getState) => {
	const { blogs } = await getState();

	if (blogs.loading) {
		return;
	}

	dispatch(BLOGS_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/blogs`)
		.then(async (res) => {
			dispatch(
				BLOGS_SUCCESS({
					blogs: res.data.sort((a, b) =>
						moment(a.date).format('YYYY-MM-DD kk:mm:ss') <
						moment(b.date).format('YYYY-MM-DD kk:mm:ss')
							? 1
							: -1
					),
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(BLOGS_ERROR());
		});
};

export default blogs;
