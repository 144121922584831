import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const integrations = createSlice({
	name: 'integrations',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		integrations: [],
		maxed: false,
	},
	reducers: {
		INTEGRATIONS_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		INTEGRATIONS_SUCCESS(state, action) {
			const { integrations, maxed, count, total } = action.payload;
			return (state = {
				loading: false,
				integrations,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		INTEGRATIONS_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: false });
		},
	},
});

export const INTEGRATIONS_LOADING = integrations.actions.INTEGRATIONS_LOADING;
export const INTEGRATIONS_SUCCESS = integrations.actions.INTEGRATIONS_SUCCESS;
export const INTEGRATIONS_ERROR = integrations.actions.INTEGRATIONS_ERROR;

export const GET_INTEGRATIONS = (props) => async (dispatch, getState) => {
	const { integrations } = await getState();

	if (integrations.loading) {
		return;
	}

	dispatch(INTEGRATIONS_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/integrations`, {
			count: integrations.count,
		})
		.then(async (res) => {
			console.log(res.data);
			let integrations2 = [
				...new Set([...integrations.integrations, ...res.data.integrations]),
			];
			let count = integrations2.length;
			let maxed = count >= res.data.integrationsCount ? true : false;
			let total = res.data.integrationsCount;

			dispatch(
				INTEGRATIONS_SUCCESS({
					integrations: integrations2,
					maxed,
					count,
					total,
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(INTEGRATIONS_ERROR());
		});
};

export default integrations;
