import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import Alert from "./components/layout/Alert";

// Styles
import "./App.css";
import { Helmet } from "react-helmet";
import Loader from "./components/UI/Loader/Loader";
import { LOAD_USER } from "./redux/reducers/auth";
import Navbar from "./components/layout/Navbar";
const NotFoundPage = lazy(() => import("./Pages/NotFoundPage"));
const Login = lazy(() => import("./Pages/Login"));
const Admin = lazy(() => import("./components/admin/Admin"));
const AdminRoute = lazy(() => import("./components/Routing/AdminRoute"));

function App() {
  //Redefine console.log function and redirect if connection is not secure
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_OG_DB === "false"
  ) {
    console.log = function () {};
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LOAD_USER());
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <>
          {/* <Navbar /> */}

          <Switch>
            <AdminRoute exact path="/">
              <Admin />
            </AdminRoute>

            <Route exact path="/login">
              <Helmet>
                <title>Login | Kashew </title>

                <meta property="og:title" content="Login | Kashew" />
                <link rel="canonical" href="https://www.kashew.com/login" />
              </Helmet>
              <Login />
            </Route>

            <Route path="*">
              <Helmet>
                <title>Not Found | Kashew </title>

                <meta property="og:title" content="Not Found | Kashew" />
              </Helmet>
              <NotFoundPage />
            </Route>
          </Switch>
        </>
        <Alert />
      </Suspense>
    </>
  );
}

export default App;
