import classes from "./Loader.module.css";
import InfinityLoad from "../../assets/InfinityLoad.svg";
const Loader = () => {
  return (
    <>
      <div className={classes.loaderBox}>
        <img
          src={InfinityLoad}
          alt={"kashew used furniture loading spinner"}
          className={classes.loaderIcon}
        />
      </div>
    </>
  );
};
export default Loader;
