//Redux store
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import alert from './reducers/alert';
import auth from './reducers/auth';
import users from './reducers/users';
import stores from './reducers/stores';
import integrations from './reducers/integrations';
import listings from './reducers/listings';
import consignments from './reducers/consignments';
import discounts from './reducers/discounts';
import orders from './reducers/orders';
import frames from './reducers/frames';
import blogs from './reducers/blogs';
import brands from './reducers/brands';
import crossListing from './reducers/crossListing';
import velouCompare from './reducers/velouData';

const store = configureStore(
	{
		reducer: {
			alert: alert.reducer,
			auth: auth.reducer,
			users: users.reducer,
			stores: stores.reducer,
			integrations: integrations.reducer,
			listings: listings.reducer,
			consignments: consignments.reducer,
			discounts: discounts.reducer,
			orders: orders.reducer,
			frames: frames.reducer,
			brands: brands.reducer,
			blogs: blogs.reducer,
			crossListing: crossListing.reducer,
			velouCompare: velouCompare.reducer,
		},
		devTools: process.env.NODE_ENV !== 'production',
	},

	applyMiddleware(thunk)
);

export default store;
