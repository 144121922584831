import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const frames = createSlice({
	name: 'frames',
	initialState: {
		loaded: false,
		loading: false,
		count: 0,
		total: 0,
		missing: [],
		shipped: [],
		delivered: [],
		maxed: false,
	},
	reducers: {
		FRAMES_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		FRAMES_SUCCESS(state, action) {
			const { shipped, missing, delivered, maxed, count, loaded, total } =
				action.payload;
			return (state = {
				loading: false,
				shipped,
				missing,
				delivered,
				total,
				maxed,
				count,
				loaded: true,
			});
		},
		FRAMES_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: false });
		},
	},
});

export const FRAMES_LOADING = frames.actions.FRAMES_LOADING;
export const FRAMES_SUCCESS = frames.actions.FRAMES_SUCCESS;
export const FRAMES_ERROR = frames.actions.FRAMES_ERROR;

export const GET_FRAMES = (props) => async (dispatch, getState) => {
	const { frames } = await getState();

	if (frames.loading) {
		return;
	}

	dispatch(FRAMES_LOADING());

	await axios
		.post(`${process.env.REACT_APP_SERVER_API}/api/admin/frames`, {
			count: props === true ? 0 : frames.count,
		})
		.then(async (res) => {
			let shipped =
				props === true
					? [...res.data.shipped]
					: [...res.data.shipped, ...frames.shipped];
			let missing =
				props === true
					? [...res.data.missing]
					: [...res.data.missing, ...frames.missing];
			let delivered =
				props === true
					? [...res.data.delivered]
					: [...res.data.delivered, ...frames.delivered];
			let count = shipped.length;
			let maxed = count >= res.data.ordersCount ? true : false;
			let total = res.data.ordersCount;

			dispatch(
				FRAMES_SUCCESS({
					missing: missing.sort((a, b) => (a.date < b.date ? 1 : -1)),
					shipped: shipped.sort((a, b) => (a.date < b.date ? 1 : -1)),
					delivered: delivered.sort((a, b) => (a.date < b.date ? 1 : -1)),
					maxed,
					count,
					total,
				})
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(FRAMES_ERROR());
		});
};

export default frames;
