import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const crossListing = createSlice({
	name: 'crossListing',
	initialState: {
		loaded: false,
		loading: false,

		listings: [],
	},
	reducers: {
		CROSSLISTING_LOADING(state, action) {
			return (state = { ...state, loading: true, loaded: false });
		},
		CROSSLISTING_SUCCESS(state, action) {
			const { listings } = action.payload;
			return (state = {
				loading: false,
				listings,
				loaded: true,
			});
		},
		CROSSLISTING_ERROR(state, action) {
			return (state = { ...state, loading: false, loaded: false });
		},
	},
});

export const CROSSLISTING_LOADING = crossListing.actions.CROSSLISTING_LOADING;
export const CROSSLISTING_SUCCESS = crossListing.actions.CROSSLISTING_SUCCESS;
export const CROSSLISTING_ERROR = crossListing.actions.CROSSLISTING_ERROR;

export const GET_CROSSLISTING = (props) => async (dispatch, getState) => {
	const { crossListing } = await getState();

	if (crossListing.loading) {
		return;
	}

	dispatch(CROSSLISTING_LOADING());

	await axios
		.get(`${process.env.REACT_APP_SERVER_API}/api/cross-listing`)
		.then(async (res) => {
			dispatch(CROSSLISTING_SUCCESS({ listings: res.data }));
		})
		.catch((err) => {
			console.log(err);
			dispatch(CROSSLISTING_ERROR());
		});
};

export default crossListing;
