import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const inistialState = {
    loading: false,
    data: [],
    paginationData: null,
};

const velouCompare = createSlice({
    name: 'velouCompare',
    initialState: inistialState,
    reducers: {
        PRODUCTS_LOADING(state, action) {
            return (state = {
                ...state,
                loading: true,
            });
        },
        PRODUCTS_LOADED(state, action) {
            return (state = {
                data: action.payload.data,
                paginationData: action.payload.paginationData,
                loading: false,
            });
        },
        PRODUCTS_ERROR(state, action) {
            return (state = {
                ...state,
                loading: false,
            });
        },
    },
});

export const PRODUCTS_LOADED = velouCompare.actions.PRODUCTS_LOADED;
export const PRODUCTS_LOADING = velouCompare.actions.PRODUCTS_LOADING;
export const PRODUCTS_ERROR = velouCompare.actions.PRODUCTS_ERROR;

export const LOAD_PRODUCTS = () => async (dispatch, getState) => {
    const { velouCompare } = getState();

    const searchParams = new URLSearchParams(window.location.search);
    let page = searchParams.get('page') || 1;

    let url = `${process.env.REACT_APP_SERVER_API}/api/admin/compareVelou/${page}`;

    if (velouCompare.loading) return;

    dispatch(PRODUCTS_LOADING());
    await axios
        .get(`${url}`)
        .then((res) => {
            console.log(res.data);
            dispatch(PRODUCTS_LOADED(res.data));
        })
        .catch((err) => {
            console.log(err);
            dispatch(PRODUCTS_ERROR());
        });
};

export default velouCompare;
